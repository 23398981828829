<template>
    <div>
        <header-portrate style="display:none" />

        <div class="m-2" style="border:1px solid #000;background:#fff">
            <posHeader />
            <v-row class="m-2">
                <v-col cols="12" style="display:flex;justify-content:center;">
                    <div class="p-2 text-center" style="border:1px solid #000;width:150px;background:orange !important;color:#000 !important">{{ lang.customers }}</div>
                </v-col>
                <div>
                    <v-row :style="`direction:`+lang.dir+`;`">
                        <v-row>
                            <v-col
                            cols="12"
                            md="3"
                            sm="12"
                            class="mt-4"
                            style=""
                            >
                                <form autocomplete="off">
                                    <label></label>
                                    <b-input-group>
                                        <b-form-input
                                            v-model="search"
                                            append-html=""
                                            :placeholder="lang.search"
                                            single-line
                                            hide-details
                                            class="inborder"
                                            style="background:#c3d9ff;"
                                        ></b-form-input>
                                        <b-input-group-prepend>
                                            <span class="input-group-text" 
                                            :style="`background:black !important;color:#fff;padding: 4px 16px;border-radius: 0;border: 1px solid #000;border-`+lang.algin+`: 0;`">
                                            {{lang.search}}<i class='fas fa-search' style="display:none;"></i></span>
                                        </b-input-group-prepend>
                                    </b-input-group>
                                    </form>
                                </v-col>
                            <v-col
                                cols="12" md="9" sm="12" class="text-end">
                                <b-button
                                v-b-modal.add_customer
                                class="btn-sm btn btn-success"
                                style="margin-top:25px;width:150px;background:transparent;border:none;color:#000"
                                id="multiply-button"
                                >
                                <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_customer}}</div>
                                </b-button>
                            </v-col>

                            <v-col cols="12" :style="`direction:`+ lang.dir + `;`">
                                <v-data-table
                                    :headers="headers"
                                    :items="tablerows"
                                    :search="search"
                                    :items-per-page="15"
                                    :page.sync="page"
                                    width="100%"
                                    id="MainTable"
                                    hide-default-footer
                                    @page-count="pageCount = $event" 
                                    fixed-header
                                >
                                <template v-slot:item="row">
                                    <tr>
                                        <td style="width:100px;text-align:center;">{{ row.item.customerid }}</td>
                                        <td style="width:100px;text-align:center;">{{ custType(row.item.customer_type) }}</td>
                                        <td style="width:100px;text-align:center;">{{ row.item.mobile1 }}</td>
                                        <td style="width:100px;text-align:center;">{{ row.item.mobile2 }}</td>
                                        <td style="text-align:center;">{{ row.item.full_name }}</td>
                                        <td style="text-align:center;" nowrap>{{ row.item.company_name }} {{ row.item.entity_name }}</td>
                                        <td style="text-align:center;">{{ row.item.company_vatid }}</td>
                                        <td style="text-align:center;">{{ parseFloat(row.item.total_invoices) }}</td>
                                        <!-- <td style="text-align:center;">{{ parseFloat(row.item.total_remain) }}</td> -->
                                        <td style="width:85px;background:darkcyan;border: 2px solid #fff !important;">
                                            <v-btn @click="getStatement(row.item)" style="box-shadow:none;font-size:0.2em;background:darkcyan;width:100%;color:#fff;height:25px !important;margin-left:5px;" v-b-modal.clientst_ids v-if="$store.state.licenseType.fullversion">{{lang.client_statement}}</v-btn>
                                        </td>
                                        <!-- <td style="width:85px;background:green;border: 2px solid #fff !important;"> -->
                                            <!-- <v-btn class="btn-sm btn-primary" v-if="$store.state.licenseType.quotation" style="font-size:0.2em;background:darkblue;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="quotOP(row.item)" v-b-toggle.add_quotation>{{lang.quotations}}</v-btn> -->
                                            <!-- <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:darkred;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="getStatement(row.item)" v-b-toggle.clientst_ids v-if="$store.state.licenseType.fullversion">{{lang.client_statement}}</v-btn> -->
                                            <!-- <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:green;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="InvOP(row.item)" v-b-toggle.add_invoice>{{lang.add_invoice}}</v-btn> -->
                                            <!-- <v-btn style="box-shadow:none;font-size:0.2em;background:green;width:100%;color:#fff;height:25px !important;margin-left:5px;" @click="InvOP(row.item)" v-b-toggle.add_invoice>{{lang.add_invoice}}</v-btn>
                                        </td> -->
                                        <td style="width:85px;background:blue;border: 2px solid #fff !important;">
                                            <!-- <v-btn class="btn-sm btn-primary" v-if="$store.state.licenseType.quotation" style="font-size:0.2em;background:darkblue;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="quotOP(row.item)" v-b-toggle.add_quotation>{{lang.quotations}}</v-btn> -->
                                            <!-- <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:darkred;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="getStatement(row.item)" v-b-toggle.clientst_ids v-if="$store.state.licenseType.fullversion">{{lang.client_statement}}</v-btn> -->
                                            <!-- <v-btn class="btn-sm btn-danger" style="font-size:0.2em;background:green;width:100px;color:#FFF;height:25px !important;margin-left:5px;border-radius:0px;" @click="InvOP(row.item)" v-b-toggle.add_invoice>{{lang.add_invoice}}</v-btn> -->
                                            <div class="viewBTN" @click="upCusto(row.item)" v-b-modal.update_customer>
                                                {{lang.update}}</div>
                                        </td>
                                    </tr>
                                    </template>
                                </v-data-table>
                            
                        </v-col>
                        <v-col cols="12" style="margin-bottom:25px;margin-top:-15px;">
                            <v-container>
                                <div class="text-center pt-2">
                                <v-pagination
                                    v-model="page"
                                    value="15"
                                    :length="pageCount"
                                    color="#000"
                                ></v-pagination>
                                </div>
                            </v-container>
                            </v-col>
                        </v-row>
                    </v-row>
                    <add-customer />
                    <add-quotation ref="addQoutFloat" />
                    <add-invoice ref="addinvoices" />
                    <update-customer ref="updatecustomer" />
                    <client-statements ref="clientStatments" />
                </div>
            </v-row>
       </div>
    </div>
</template>

<script>
import axios from 'axios'
import AddCustomer from '@/components/pos-addCustomer.vue'
import AddQuotation from '@/components/addQuotation.vue'
import AddInvoice from '@/components/addInvoice.vue'
import UpdateCustomer from '@/components/pos-update-customer.vue'
import HeaderPortrate from '@/components/Header-Portrate.vue'
import posHeader from '@/components/pos-header.vue'
import ClientStatements from '@/components/pos-clientStatements.vue'
export default{
    name: 'clientlist',
    components: { posHeader,AddCustomer,AddQuotation, AddInvoice, UpdateCustomer, HeaderPortrate, ClientStatements},
    data() {
        return {
            openQuot: false,
            
            page: 1,
            pageCount: 0,
            search: '',
            tablerows: [
               
            ],
            opselected: {},
            ifpselected: {},
            invorquot: 0,
            tab1: 1,
            tab2: 2,
            tab3: 3,
            ccustomer: {}
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return  {
                text: this.lang.customers,
                disabled: true,
                href: '/clientList',
            };
        },
        currentCus: function() {
            if(this.invorquot == 1){
                return {
                    id: this.opselected.id,
                    full_name: this.opselected.full_name,
                    mobile: this.opselected.mobile1,
                    company: {
                        fullname: this.opselected.company_name,
                        vatid: this.opselected.company_vatid,
                    }
                };
            }else{
                return {
                    id: this.ifpselected.id,
                    full_name: this.ifpselected.full_name,
                    mobile: this.ifpselected.mobile1,
                    company: {
                        fullname: this.ifpselected.company_name,
                        vatid: this.ifpselected.company_vatid,
                    }
                };
            }
        },
        headers: function() {
            let headers = [
                { 
                    text: this.lang.customerid, align: 'center',
                    filterable: true, value: 'customerid',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.customer_type, align: 'center',
                    filterable: true, value: 'customer_type',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.mobile, align: 'center',
                    filterable: true, value: 'mobile',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.additional_mobile, align: 'center',
                    filterable: true, value: 'mobile1',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.customer_name, align: 'center',
                    filterable: true, value: 'full_name',
                    sortable: true,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.company_name, align: 'center',
                    filterable: true, value: 'company_name',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.company_vatid, align: 'center',
                    filterable: true, value: 'vatid',
                    sortable: false,
                    class: 'backBlack'
                },
                { 
                    text: this.lang.total_invoices, align: 'center',
                    filterable: true, value: 'vatid',
                    sortable: false,
                    class: 'backBlack'
                },
                // { 
                //     text: this.lang.total_remain, align: 'center',
                //     filterable: true, value: 'vatid',
                //     sortable: false,
                //     class: 'backBlack'
                // },
                { 
                    text: this.lang.client_statement, align: 'center',
                    filterable: false,
                    sortable: false,
                    class: 'backBlack',
                    printRemove: 1
                },
                // { 
                //     text: this.lang.add_invoice, align: 'center',
                //     filterable: false,
                //     sortable: false,
                //     class: 'backBlack',
                //     printRemove: 0
                // },
                { 
                    text: this.lang.action, align: 'center',
                    filterable: false,
                    sortable: false,
                    class: 'backBlack',
                    printRemove: 0
                },
            ];
            return headers;
        },
        tabs: function(){
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
    methods: {
        custType(id){
            if(id == 1) return this.lang.individual_client
            else if(id == 2) return this.lang.company_clinet
            else if(id == 3) return this.lang.government_clinet
        },
        preparedata(){
            let exdata = {
                list: 'clientList',
                title: this.lang.customers,
                data: [],
            }
            for(let i=0;i<this.tablerows.length;i++){
                exdata.data.push(this.tablerows[i]);
            }
            // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = '';
            this.$refs.exportbar.edate = '';
            
            this.$refs.exportbar.exporttitle = "";
            this.$refs.exportbar.palte_number = '';
            this.$refs.exportbar.contact_number = '';
        },
        getCurrentCards(){
            //
        },
        upCusto(item){
            console.log("item" ,item);
            this.$refs.updatecustomer.customers = item;
            this.$refs.updatecustomer.customers.mobile = item.mobile1;
            this.$refs.updatecustomer.customers.mobile1 = item.mobile2;
            this.$refs.updatecustomer.customers.customer_type = item.customer_type;
            
            this.$refs.updatecustomer.firstUpdate();
            this.$refs.updatecustomer.changeCusType(parseInt(item.customer_type) - 1);
        },
        quotOP(item){
            this.$refs.addQoutFloat.company = {
                full_name: item.fullname,
                mobile: item.mobile
            };
            this.$refs.addQoutFloat.customer.id = item.id;
            this.$refs.addQoutFloat.customer.mobile = item.mobile1;
            this.$refs.addQoutFloat.customer.full_name = item.full_name;
            this.$refs.addQoutFloat.company.fullname = item.company_name;
            this.$refs.addQoutFloat.company.vatid = item.company_vatid;
        },
        InvOP(item){
            this.$refs.addinvoices.customer.mobile = item.mobile1;
            this.$refs.addinvoices.getName();
            
            if(item.company_name != ''){
                this.$refs.addinvoices.customer.cctype = 2;
                this.$refs.addinvoices.changeCtype();
            }else{
                this.$refs.addinvoices.customer.cctype = 1;
                this.$refs.addinvoices.changeCtype();
            }
        },
        getClients() {
            const post = new FormData();
            post.append('type',"getClients");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]",1);

            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // console.log(response.data);
                this.tablerows = response.data.results.data;
            })
        },
        getStatement(item){
            // this.$router.push({path: '/client-statement/'+item.id})
            let classid = parseFloat(item.customerid);
            // if(parseFloat(item.customerid) < 6000){
            //     classid = parseFloat(item.customerid) + parseFloat(6000);
            // }
            this.$refs.clientStatments.accountn.full_name = item.full_name;
            this.$refs.clientStatments.accountn.customerid = classid;
            this.$refs.clientStatments.getReport();
            this.$refs.clientStatments.getReport();
        }
    },
    created() {
        this.getClients();
    },
}
</script>
<style>
.scrollMe{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    position:absolute;
    text-align:center;
    left:2px;
    width:50px;
    top:130px;
    bottom:40px;
}
.addButtom{
  position:absolute;
  text-align:center;
  left:0px;
}
th {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}
.invoicetable{
    max-height:calc(100vh - 325px);
    min-height:calc(100vh - 325px);
    overflow: auto;
}
#countbtns{
    background:#eee;
    border:1px solid #ccc;
    text-align:center;
    position: absolute;
    bottom: 1px;
    left:1px;
    right:1px;
}
.mxmnheight{
    max-height:calc(100vh - 100px);
    min-height:calc(100vh - 100px);
    position: relative;
}
.mxmnheight-200{
    max-height:calc(100vh - 250px);
    overflow: auto;
}
.wp-100{
    width: 150px;
    padding:10px;
    margin-inline-start: 3px;
}
.wp-150{
    padding-top:10px;
    width: calc(95%/5);
    height:100px;
    margin-inline-start: 3px;
    margin-top: 3px;
    background:#fff;
    color:#000;
    transition: 1s;
}
.wp-150:hover{
    /* background:#222;
    color:#fff; */
    transition: 1s;
}
.itemrowstd{
    transition: 1s;
    height: 40px !important;
}
.btn-gray{
    background:#fff;
    color: #000;
    border-radius: 7px;
}
.btn-wght{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    position: relative;
    padding-top:25px !important;

}
.btn-wght:active,.btn-wght:hover,.btn-wght:visited{
    background:#fff;
    color: #000;
    border-radius: 0px;
    padding:0px !important;
    
}
.btn-orange,.btn-orange:active,.btn-orange:hover,.btn-orange:visited{
    background: #fd7e14 !important;
    border-radius: 7px;
}
.btn-yallow,.btn-yallow:active,.btn-yallow:hover,.btn-yallow:visited{
    background: #ffc107 !important;
    color:black !important;
    border-radius: 7px;
}
.btn-primary {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
    border-radius: 7px;
}
.rowPrice{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    background:green;
    color:#fff;
    text-align:center;
    padding:3px;
    border:1px solid #000;
}
.gradBG{
    background: rgb(31,43,153) !important;
background: linear-gradient(180deg, rgba(31,43,153,1) 0%, rgba(31,87,153,1) 3%, rgba(31,89,153,1) 18%, rgba(31,103,153,1) 50%, rgba(31,83,153,1) 82%, rgba(31,64,153,1) 97%, rgba(31,43,153,1) 100%) !important;
}
.flexRow{
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
}
.flexRow button{
    margin-inline-end: 10px;
}
.flexit{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: center;
    align-items: center;
}
.modal-header,.modal-footer{
    padding:5px 10px 0 0 !important;
    background:black !important ;
}
.modal-footer > * {
    margin:0;
}
.modal{
    top:40px;
}
</style>